import { useCallback, useMemo, useRef } from 'react'
import { debounce } from 'utils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as Quill from 'quill'
import { compressImage, uploadImageToServer } from 'api/uploadImage'

const toolbarOptions = [
	['link'],
	[{ header: [1, 2, 3, false] }],
	['bold', 'italic', 'underline', 'strike'],
	[{ color: [] }, { background: [] }],
	['image', 'video']
]

const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	// 'align',
	'background',
	'color',
	'link',
	'image',
	'video',
	'width'
]

interface TextEditorProps {
	value?: string | Quill.Delta
	placeholder?: string
	onChange?: (content: string) => void
	className?: string
}

const TextEditor = ({ value, placeholder, onChange, className, ...rest }: TextEditorProps) => {
	const quillRef = useRef<ReactQuill>(null)

	const imageHandler = useCallback(() => {
		const input = document.createElement('input')
		input.setAttribute('type', 'file')
		input.setAttribute('accept', 'image/*')
		input.click()

		input.onchange = async () => {
			const file = input.files?.[0]
			if (file) {
				const compressedFile = await compressImage(file)
				const imageUrl = await uploadImageToServer(compressedFile)
				if (imageUrl && quillRef.current) {
					const editor = quillRef.current.getEditor()
					const range = editor.getSelection() ?? { index: 0 }
					editor.insertEmbed(range.index, 'image', imageUrl)
				}
			}
		}
	}, [])

	const videoHandler = useCallback(() => {
		const url = prompt('YouTube 비디오 URL을 입력하세요:')
		if (url) {
			const videoId = getYouTubeVideoId(url)
			if (videoId && quillRef.current) {
				const editor = quillRef.current.getEditor()
				const range = editor.getSelection() ?? { index: 0 }
				const embedHtml = `<div style='width: 100% aspact-ratio: 16/9'><iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>`
				editor.clipboard.dangerouslyPasteHTML(range.index, embedHtml)
			}
		}
	}, [])

	const getYouTubeVideoId = (url) => {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
		const match = url.match(regExp)
		return match && match[2].length === 11 ? match[2] : null
	}

	const modules = useMemo(
		() => ({
			toolbar: {
				container: toolbarOptions,
				handlers: {
					image: imageHandler,
					video: videoHandler
				}
			},
			clipboard: {
				matchVisual: false
			}
		}),
		[imageHandler, videoHandler]
	)

	const convertNewlinesToBr = (text: string) => {
		return text.replace(/\n/g, '<br>')
	}

	const processedValue = typeof value === 'string' ? convertNewlinesToBr(value) : value

	return (
		<ReactQuill
			ref={quillRef}
			{...rest}
			placeholder={placeholder}
			defaultValue={processedValue || ''}
			className={className}
			onChange={(content, delta, source, editor) => onChange && debounce(() => onChange(editor.getHTML()), 500)}
			theme="snow"
			modules={modules}
			formats={formats}
		/>
	)
}

export default TextEditor
