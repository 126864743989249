import axiosV2 from 'api/axiosV2'

// 광고 - 광고주
export async function getManagerList(params) {
	const res = await axiosV2.get('/manager/list', { params })
	return res.data
}

export async function getManagerDetail(managerSeq) {
	const res = await axiosV2.get(`/manager/detail/${managerSeq}`)
	return res.data
}

export async function registManager(data) {
	const res = await axiosV2.post(`/manager/register`, data)
	return res.data || res
}

export async function updateManager(data) {
	const res = await axiosV2.post(`/manager/update/${data.seq}`, data)
	return res.data || res
}

export async function deleteManager(managerSeq) {
	const res = await axiosV2.delete(`/manager/delete/${managerSeq}`)
	return res.data
}

// 광고 - 캠페인
export async function getCampaignList(managerSeq = '', params) {
	const res = await axiosV2.get(`/campaign/list/${managerSeq}`, { params })
	return res.data
}

export async function getCampaignDetail(campaignSeq) {
	const res = await axiosV2.get(`/campaign/detail/${campaignSeq}`)
	return res.data
}

export async function registCampaign(data) {
	const res = await axiosV2.post(`/campaign/register`, data)
	return res.data || res
}

export async function updateCampaign(seq, data) {
	const res = await axiosV2.post(`/campaign/update/${seq}`, data)
	return res.data || res
}

export async function deleteCampaign(campaignSeq) {
	const res = await axiosV2.delete(`/campaign/delete/${campaignSeq}`)
	return res.data
}

// 광고 - 단가
export async function getCostList(campaignSeq = '', params) {
	const res = await axiosV2.get(`/cost/list/${campaignSeq}`, { params })
	return res.data
}

export async function registCost(data) {
	const res = await axiosV2.post(`/cost`, data)
	return res.data || res
}

export async function updateCost(costSeq, data) {
	const res = await axiosV2.post(`/cost/update/${costSeq}`, data)
	return res.data || res
}

export async function deleteCost(costSeq) {
	const res = await axiosV2.delete(`/cost/delete/${costSeq}`)
	return res.data
}

// 광고 - 소재
export async function getSubjectList(params) {
	const res = await axiosV2.get(`/ads/list`, { params })
	return res.data
}

export async function getSubjectDetail(subjectSeq) {
	const res = await axiosV2.get(`/ads/product/${subjectSeq}`)
	return res.data
}

export async function registSubject(data) {
	const res = await axiosV2.post(`/ads/set`, data)
	return res.data
}

export async function updateSubject(subjectSeq = '', param) {
	const res = await axiosV2.post(`/ads/update/${subjectSeq}`, param)
	return res.data || res
}

export async function deleteSubject(subjectSeq) {
	const res = await axiosV2.delete(`/ads/delete/${subjectSeq}`)
	return res.data
}

export async function getCityList() {
	const res = await axiosV2.get('/location/city')
	return res.data
}

// 광고 - 이미지 업로드
export async function adsImageUpload(formData) {
	const res = await axiosV2.post('/image/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
	return res.data
}
