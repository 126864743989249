import { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import themeStyles from './studyplace.theme.style'
import Button from '@material-ui/core/Button'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import AddEventDialog from './addEventDialog'
import CafeEditDialog from './cafeEditDialog'
import BizEditDialog from './bizEditDialog'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import axios from '../../api/axios'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import scss from './studyplace.module.scss'
import { isMobile } from 'react-device-detect'
import kindData from './data/kindData.json'
import Pagination from 'react-js-pagination'
import Place from 'api/place'
import { debounce } from 'utils'

const StudyRoom = ({ classes }) => {
	const [_placeList, set_placeList] = useState([])
	const [placeList, setPlaceList] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [sizePerPage, setSizePerPage] = useState(10)
	const [listTotal, setListTotal] = useState(0)
	const [placeSeq, setPlaceSeq] = useState(null)
	const [selectedService, setSelectedService] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [isService, setIsService] = useState(null)

	useEffect(() => {
		if (!(sessionStorage.getItem('manager_permission') >= 7)) {
			window.location.href = '/dashboard'
		}

		if (!window.kakao || !window.kakao.maps) {
			addScript()
		}
		loadData()
	}, [])

	useEffect(() => {
		loadData()
	}, [currentPage, sizePerPage, isService])

	const addScript = () => {
		try {
			const script = document.createElement('script')
			script.async = true
			script.src = 'https://dapi.kakao.com/v2/maps/sdk.js?appkey=42443970b9dcfeeae637e6375b532d28&libraries=services&autoload=false'
			document.head.appendChild(script)
		} catch (error) {
			console.log(error)
		}
	}

	const loadData = async () => {
		try {
			const params = {
				page: currentPage,
				perPage: sizePerPage,
				search: searchValue,
				isService: isService
			}
			const res = await Place.getPlaceInfo(params)

			if (res?.status == 200) {
				console.log(res.data)
				res.data &&
					res.data.list &&
					res.data.list.forEach((el) => {
						el.isPublic = el.isPublic ? '공개' : '미공개'
						if (el.isOpen == '1') el.isOpen = '오픈'
						else if (el.isOpen == '0') el.isOpen = '비오픈'
						else el.isOpen = '사용중지'
					})

				if (res.data.list) {
					setPlaceList(res.data.list)
				}

				set_placeList(res.data.list)
				setListTotal(res.data.total)
			} else {
				throw new Error(res)
			}
		} catch (error) {
			console.error(error)
		}
	}

	//Search 오른쪽 버튼 Custom
	const createCustomClearButton = (onClick) => {
		return (
			<button className="btn btn-warning" onClick={onClick}>
				초기화
			</button>
		)
	}

	//신규 등록 버튼 Custom

	const createCustomInsertButton = () => {
		return <AddEventDialog onClose={closeEvent} />
	}

	//Dialog Close Event

	const closeEvent = (data) => {
		if (!data || !data.flag) return
		else if (data.flag === 'success') {
			alertMessage('완료', data.message, 'success')
			loadData()
		} else if (data.flag === 'fail') {
			alertMessage('저장실패', data.message, 'danger')
		} else if (data.flag === 'error') {
			alertMessage('에러', data.message, 'danger')
		} else if (data.flag === 'delete') {
			Place.deletePlace(placeSeq)
				.then((res) => {
					if (res.data && res.data.result === 'success') {
						alert('삭제 되었습니다.')
						loadData()
					}
				})
				.catch((error) => {
					alert('에러가 발생하였습니다.[' + JSON.stringify(error) + ']')
					console.error(error)
				})
		}
	}

	//접속 버튼 Click Event
	const onClickConnect = async (cell, row, rowIndex) => {
		try {
			const res = await Place.getPlaceConnectInfo(row['seq'])
			if (res.data.result === 'success') {
				localStorage.setItem('manager_place', JSON.stringify({ key: row['key'], seq: row['seq'], name: row['name'] }))
				localStorage.setItem('access_token', res.data.access_token)
				axios.defaults.headers.common['access_token'] = res.data.access_token
				window.location.href = '/dashboard'
			}
		} catch (error) {
			console.error(error)
		}
	}

	// //수정 버튼 Click Event
	const onClickEdit = (cell, row, rowIndex, clickedDomId) => {
		setPlaceSeq(row['seq'])
		const hiddenButton = document.getElementById(clickedDomId)
		if (hiddenButton) {
			hiddenButton.click()
		}
	}
	// 카페정보 클릭
	const onClickCafeInfo = (cell, row, rowIndex) => {
		onClickEdit(cell, row, rowIndex, 'editDialog_btn')
	}
	// 사업자정보 클릭
	const onClickCompany = (cell, row, rowIndex) => {
		onClickEdit(cell, row, rowIndex, 'companyDialog_btn')
	}

	/**
	 * Message 출력
	 */
	const alertMessage = (title, message, type) => {
		Store.addNotification({
			title: title,
			message: message,
			type: type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: { duration: 3000 },
			dismissable: { click: true }
		})
	}

	const cellButton = (cell, row, enumObject, rowIndex) => {
		return (
			<div className={scss.button_div}>
				<Button variant="outlined" className={scss.edit_btn} onClick={() => onClickCafeInfo(cell, row, rowIndex)}>
					{Number(window.sessionStorage.getItem('manager_permission')) === 9 ? '수정' : '상세'}
				</Button>
				<Button variant="outlined" className={scss.company_btn} onClick={() => onClickCompany(cell, row, rowIndex)}>
					사업자정보
				</Button>
				<Button variant="outlined" className={scss.connect_btn} onClick={() => onClickConnect(cell, row, rowIndex)}>
					접속
				</Button>
			</div>
		)
	}

	const typeFormat = (cell) => {
		return kindData.find((e) => e.value === cell).label || ''
	}

	const serviceTypeChange = (event, value) => {
		setSelectedService(value)
		setCurrentPage(1)
		value === '' && setIsService(null)
		value === 'Y' && setIsService(true)
		value === 'N' && setIsService(false)
	}

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber)
	}

	const handlePerPageChange = (perPage) => {
		setCurrentPage(1)
		setSizePerPage(perPage)
	}

	const handleChange = (event) => {
		setSearchValue(event.target.value)
	}

	const onKeyDown = (event) => {
		debounce(() => {
			if (event.key === 'Enter') {
				event.preventDefault()
				event.stopPropagation()
				onSearch()
			}
		}, 300)
	}

	const onSearch = async () => {
		setCurrentPage(1)
		await loadData(searchValue ? searchValue : 'clear')
	}

	const [options] = useState({
		defaultSortName: 'seq', // default sort column name
		defaultSortOrder: 'desc', // default sort order
		clearSearch: true,
		noDataText: '데이터 없음',
		clearSearchBtn: createCustomClearButton,
		insertBtn: createCustomInsertButton
	})

	return (
		<div className={scss.container}>
			<div
				id="main"
				className={scss.study_place_div}
				style={{
					minWidth: isMobile ? '100%' : '800px',
					margin: isMobile ? '0' : null,
					fontSize: isMobile ? '0.8rem' : null,
					position: 'relative'
				}}
			>
				<ReactNotifications />
				<div style={{ margin: isMobile ? '5%' : null }}>
					<div style={{ margin: isMobile ? '5%' : null, width: '67vw', display: 'flex', justifyContent: 'space-between' }}>
						<div>
							<AddEventDialog onClose={closeEvent} />
						</div>

						<RadioGroup
							aria-label="Payment"
							name="Payment"
							row={true}
							className={`${classes.group} ${scss.select_bar} ${
								isMobile && 'static transform-none'
							} flex-nowrap whitespace-nowrap`}
							value={selectedService}
							onChange={serviceTypeChange}
						>
							<FormControlLabel value={''} control={<Radio />} label="전체" classes={{ label: 'dark:text-moaWhite' }} />
							<FormControlLabel value={'Y'} control={<Radio />} label="입점" classes={{ label: 'dark:text-moaWhite' }} />
							<FormControlLabel value={'N'} control={<Radio />} label="미입점" classes={{ label: 'dark:text-moaWhite' }} />
						</RadioGroup>

						<div style={{ display: 'flex', justifySelf: 'flex-end' }}>
							<input
								className="form-control h-7 w-4/5 text-xs"
								type="text"
								placeholder={'공간명 검색'}
								value={searchValue}
								onChange={handleChange}
								onKeyDown={(event) => onKeyDown(event)}
								style={{ zIndex: '0' }}
							/>
							<span className="input-group-btn">
								<button className="btn btn-default h-7 p-0 px-2" onClick={onSearch.bind(this)}>
									<span className="m-auto text-xs">검색</span>
								</button>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div style={{ margin: isMobile ? '2% 5%' : null }}>
				<BootstrapTable data={placeList} options={options} hover className={'study_place_bs'}>
					<TableHeaderColumn dataField="key" isKey dataSort width="0">
						Key
					</TableHeaderColumn>
					<TableHeaderColumn dataField="seq" dataSort width="70px">
						No.
					</TableHeaderColumn>
					<TableHeaderColumn dataField="placeType" dataSort dataFormat={typeFormat.bind(this)} width="90px">
						종류
					</TableHeaderColumn>
					<TableHeaderColumn dataField="name" dataSort width="200px">
						공간명
					</TableHeaderColumn>
					<TableHeaderColumn dataField="isPublic" dataSort width="90px">
						상태
					</TableHeaderColumn>
					<TableHeaderColumn dataField="isOpen" dataSort width="90px">
						오픈
					</TableHeaderColumn>
					<TableHeaderColumn dataField="button" dataFormat={cellButton.bind(this)} width="260px"></TableHeaderColumn>
				</BootstrapTable>
			</div>

			<div className="btn-group" style={{ width: '100%', padding: isMobile ? '0 5%' : null }}>
				{[10, 20, 50].map((n, idx) => {
					const isActive = n == sizePerPage ? 'active' : null
					return (
						<button
							key={idx}
							type="button"
							style={{ margin: isMobile ? '0' : '20px 0' }}
							className={`btn ${isActive}`}
							onClick={() => handlePerPageChange(n)}
						>
							{n}
						</button>
					)
				})}
				<div style={{ float: 'right', width: isMobile ? '100%' : null, textAlign: isMobile ? 'right' : null }}>
					<Pagination
						activePage={currentPage}
						itemsCountPerPage={sizePerPage}
						totalItemsCount={listTotal}
						pageRangeDisplayed={5}
						onChange={(event) => handlePageChange(event)}
					/>
				</div>
			</div>

			<div className="hidden_">
				<CafeEditDialog className="hidden_" placeSeq={placeSeq} onClose={closeEvent} />
				<BizEditDialog className="hidden_" placeSeq={placeSeq} onClose={closeEvent} />
			</div>
		</div>
	)
}

export default compose(withWidth(), withStyles(themeStyles, { withTheme: true }))(StudyRoom)
