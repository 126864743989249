// eslint-disable-next-line import/named
import { Button, Dialog, DialogActions, FormControlLabel, FormGroup, Switch, TextField, withStyles } from '@material-ui/core'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { purple } from '@material-ui/core/colors'
import { useCallback, useRef, useState } from 'react'
import { alertMessage } from 'common/utils'
import { Board, ImageForm } from 'types/Board'
import TextEditor from 'components/TextEditor'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { useAppDispatch, useAppSelector } from 'reducers'
import { endLoading, startLoading } from 'reducers/common.reducer'
import CircleLoader from 'components/CircleLoader'
import { postNews } from '../utils/NewsApi'

const styles = (theme) => {
	return {
		colorSwitchBase: {
			color: purple[300],
			'&$colorChecked': {
				color: '#8EC137',
				'& + $SliderBar': {
					backgroundColor: '#626262'
				}
			}
		},
		SliderBar: {
			transition: theme.transitions.create(['background-color'])
		},
		colorChecked: {
			color: '#8EC137'
		}
	}
}
interface AdminDialogProps {
	open: boolean
	onSave?: () => void
	onCancel: () => void
	onDelete?: (body: any) => void
	board?: Board
}

export interface BoardFormValue {
	title: string
	subTitle: string
	content: string
	linkUrl?: string
	startDt?: string
	endDt?: string
	isPublished: boolean
	isAlert?: boolean
	show: string
	imgUrl1?: string | null
}

const AddDialog = ({ open, onCancel, onDelete, board }: AdminDialogProps) => {
	const dispatch = useAppDispatch()
	const isLoading = useAppSelector((state) => state.common.isLoading)
	const { control, reset, handleSubmit, watch, getValues } = useForm<BoardFormValue>({
		defaultValues: {
			...board,
			startDt: board?.startDt && moment(board?.startDt).format('YYYY-MM-DD'),
			endDt: board?.endDt && moment(board?.endDt).format('YYYY-MM-DD')
		}
	})

	const isPublished = useRef<boolean | string | null>(null)
	const isAlert = useRef<boolean | null | undefined>(null)
	isAlert.current = watch('isAlert')
	isPublished.current = watch('isPublished')

	const exitHandler = useCallback(() => {
		dispatch(endLoading())
		onCancel()
		reset()
	}, [open])

	const onValid = async (data: BoardFormValue) => {
		dispatch(startLoading())

		const dataContentCheck = data.content?.split('<p><br></p>').filter((v) => v).length === 0
		if (!data.title) {
			alertMessage({ title: '알림', message: '제목은 필수사항입니다.', type: 'warning' })
			dispatch(endLoading())
			return
		}
		if (!data.content || dataContentCheck) {
			alertMessage({ title: '알림', message: '내용은 필수사항입니다.', type: 'warning' })
			dispatch(endLoading())
			return
		}

		try {
			const res = await postNews(data)
			if (res.success) {
				alertMessage({ title: '알림', message: '게시글이 등록되었습니다.', type: 'success' })
			} else {
				alertMessage({ title: '알림', message: '에러가 발생했습니다.', type: 'danger' })
			}
			return
		} catch (e) {
			alertMessage({ title: '알림', message: '에러가 발생했습니다.', type: 'danger' })
			console.error(e)
		} finally {
			dispatch(endLoading())
			return
		}
	}

	return (
		<>
			<Dialog aria-labelledby="customized-dialog-title" className="customDialog" open={open} onClose={exitHandler}>
				{isLoading && (
					<div className="fixed top-0 left-0 bg-[rgba(0,0,0,0.3)] w-screen h-screen z-[10000]">
						<CircleLoader />
					</div>
				)}
				<Main onSubmit={handleSubmit(onValid)}>
					<DialogTitle>
						<Controller
							control={control}
							name="title"
							render={({ field }) => (
								<TextField
									placeholder="제목을 입력해주세요"
									fullWidth
									InputProps={{
										disableUnderline: true
									}}
									{...field}
								/>
							)}
						/>
					</DialogTitle>
					<DialogContent>
						<div className="row" style={{ borderBottom: '1px solid rgba(0,0,0, 0.12)' }}>
							<Controller
								control={control}
								name="content"
								render={({ field }) => (
									<TextEditor
										{...field}
										className="row-md-2 [&_p_img]:w-[300px] [&_p_img]:h-[200px] [&_p_img]:object-contain"
										placeholder="공지내용을 입력해주세요"
									/>
								)}
							/>
						</div>
					</DialogContent>
					<BottomGroup className="p-4 pb-1 flex-col gap-6">
						<div className="w-full flex justify-between items-end">
							<DateContainer className="">
								<div className="space-x-2">
									<Controller
										name="startDt"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												helperText={isMobile && !getValues().startDt ? '시작일 설정하기' : null}
												type="date"
												label="시작일"
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>

									<Controller
										name="endDt"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												helperText={isMobile && !getValues().endDt ? '종료일 설정하기' : null}
												type="date"
												label="종료일"
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</div>
							</DateContainer>
							<div className="flex justify-between mb-[-14px]">
								<Controller
									control={control}
									name="isPublished"
									render={({ field }) => (
										<FormControlLabel
											control={<Switch checked={field.value} disableRipple {...field} />}
											label={isPublished.current ? '공개' : '비공개'}
										/>
									)}
								/>
							</div>
						</div>
						<DialogActions>
							<Button variant="outlined" onClick={handleSubmit(onValid)} color="primary" autoFocus>
								저장
							</Button>
							<Button
								variant="outlined"
								onClick={() => onDelete && onDelete(board?.seq)}
								color="secondary"
								style={{ float: 'left' }}
							>
								삭제
							</Button>
							<Button variant="outlined" onClick={onCancel} color="default">
								닫기
							</Button>
						</DialogActions>
					</BottomGroup>
				</Main>
			</Dialog>
		</>
	)
}

export default withStyles(styles, { withTheme: true })(AddDialog)

const Main = styled.form`
	width: 100%;
	padding: 0.25rem;
`
const BottomGroup = styled(FormGroup)`
	align-items: flex-end;
`
const DialogTitle = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`
const DialogContent = styled.div`
	margin: 0;
	position: relative;
	padding: 16px;
	padding-bottom: 0;
	min-width: 500px;
	flex: 1 1 auto;
	overflow-y: auto;
	& > div {
		height: 100%;
	}
	@media screen and (max-width: 425px) {
		min-width: 90vw;
	}
`

const DateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 425px) {
		flex-direction: column;
		& div {
		}
	}
`
